@use '../utils/variables.scss' as *;

.search-form {
    .search-wrapper {
        align-items: center;
        background-color: $white;
        display: flex;
        height: 80px;
        left: 0;
        position: absolute;
        top: 0;
        width: calc(100% - 100px);

        @media (min-width: 576px) {
            max-width: 72%;
        }

        @media (min-width: 768px) {
            max-width: 80%;
        }

        @media (min-width: 860px) {
            max-width: 76%;
        }

        @media (min-width: 992px) {
            height: 100%;
            width: calc(100% - 70px);
        }

        @media (min-width: 1200px) {
            max-width: 100%;
        }
    }

    .search-input {
        border: 0;
        color: $primary;
        font-size: $fs-p2;
        margin: 0;
        width: 100%;

        &:hover,
        &:focus {
            border: 0;
        }
    }

    ::placeholder {
        color: $primary;
    }
}

.search-icon {
    border: 0;
    padding: 0;

    &:hover,
    &:focus {
        border: 0;
    }
}
