@use './../utils/variables' as *;

.nav-tabs {
    border: 0;

    .nav-link {
        background-color: map-get($get-primary, '200');
        border: 0;
        border-radius: $border-radius;
        color: $primary;
        display: inline-block;
        font-family: 'Poppins-Bold', sans-serif;
        margin: 12px;
        min-width: 305px;
        padding: 20px 0;
        position: relative;
        text-align: center;

        &.active {
            background-color: $primary;
            color: $white;

            &::after,
            &::before {
                border: solid transparent;
                bottom: -2px;
                content: '';
                height: 0;
                left: 50%;
                pointer-events: none;
                position: absolute;
                width: 0;
            }

            &::after {
                border-color: transparent;
                border-bottom-color: $white;
                border-width: 12px;
                margin-left: -12px;
            }

            &::before {
                border-color: transparent;
                border-bottom-color: $white;
                border-width: 6px;
                margin-left: -6px;
            }
        }
    }
}
