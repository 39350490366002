@use '../utils/variables' as *;

.visual {
    position: relative;
}

.category-icon {
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    bottom: 25px;
    left: 25px;
    position: absolute;

    @media (min-width: 768px) {
        bottom: 40px;
        left: 40px;
    }
}

.videa-icon {
    background: url('./../img/videa-icon.svg') #3360a9 no-repeat center center;
    height: 60px;
    width: 60px;
}

.podcasty-icon {
    background: url('./../img/podcasty-icon.svg') #3360a9 no-repeat center center;
    height: 60px;
    width: 60px;
}

.knihy-icon {
    background: url('./../img/book-icon.svg') #3360a9 no-repeat center center;
    height: 60px;
    width: 60px;
}

.tiskoviny-icon {
    background: url('./../img/file-earmark-text-fill.svg') #3360a9 no-repeat center center;
    height: 60px;
    width: 60px;
}
