@use '../utils/variables' as *;

.slug-novinky,
.slug-ke-stazeni,
.slug-download,
.slug-akce {

    // row
    .djangocms-newsblog-article-list {
        @media (min-width: 768px) {
            padding-top: 12px;
        }

        display: flex;
        flex-wrap: wrap;
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-top: calc(-1 * var(--bs-gutter-y));

        > * {
            flex-shrink: 0;
            margin-top: var(--bs-gutter-y);
            max-width: 100%;
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            width: 100%;
        }
    }

    // col
    article.article {
        margin: space(2) 0;

        @media (min-width: 992px) {
            flex: 0 0 auto;
            margin: space(3) 0;
        }
    }
}

main[class^="slug-"] {

    //row
    .aldryn-newsblog-articles,
    .aldryn-newsblog-latest-articles,
    .related-article {
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-top: calc(-1 * var(--bs-gutter-y));

        > * {
            flex-shrink: 0;
            margin-top: var(--bs-gutter-y);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            padding-right: calc(var(--bs-gutter-x) * 0.5);
        }
    }
}

.slug-ke-stazeni,
.slug-download {
    article.article {
        background: bg-column-aldryns(map-get($get-secondary, '100'));
        width: 100%;

        @media (min-width: 992px) {
            width: 33.33%;
        }
    }

    .djangocms-newsblog-article-list {
        h2 {
            @media (min-width: 768px) {
                font-size: $fs-p1;
                padding-top: 12px;
            }

            a {
                @media (min-width: 992px) {
                    min-height: 54px;
                }
            }
        }

        .lead {
            display: block;
        }
    }
}

.slug-novinky,
.slug-news {
    background-color: map-get($get-primary, '100');

    article.article {
        background: bg-column-aldryns($white);
        width: 50%;

        @media (min-width: 992px) {
            width: 25%;
        }
    }

    .aldryn-download article.article {
        background: bg-column-aldryns($white);
        margin: space(2) 0;
        width: 100%;

        @media (min-width: 992px) {
            flex: 0 0 auto;
            margin: space(3) 0;
            width: 33.333%;
        }

        h2 {

            @media (min-width: 768px) {
                font-size: $fs-p1;
                padding-top: 0.8rem;
            }
        }
    }

    .mt-1.mb-3.pb-3.pb-md-4 {
        margin-bottom: 10 !important;
        padding-bottom: 0 !important;
    }

    .djangocms-newsblog-article-list {
        @media (min-width: 768px) {
            padding-top: 6px;
        }
    }
}

.slug-akce,
.slug-actions {
    article.article {
        background: bg-column-aldryns(map-get($get-secondary, '100'));
        width: 100%;

        @media (min-width: 992px) {
            width: 50%;
        }
    }

    .djangocms-newsblog-article-list {
        article.article {
            display: flex;
            flex-direction: column;
        }

        .visual {
            order: 1;

            img {
                height: 170px;
                object-fit: cover;
                width: 100%;

                @media (min-width: 768px) {
                    height: 364px;
                }

                @media (min-width: 1900px) {
                    height: 400px;
                }
            }

        }

        h2 {
            order: 2;

            @media (min-width: 768px) {
                font-size: $fs-h4;
                padding-bottom: space(3);
                padding-top: space(2);
            }

            a {
                @media (min-width: 992px) {
                    min-height: 60px;
                }
            }
        }

        .lead {
            display: block;
            order: 4;

            @media (min-width: 768px) {
                p {
                    font-size: $fs-p2;
                }
            }
        }

        .meta {
            display: none;
        }
    }
}
