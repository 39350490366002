@use '../utils/variables.scss' as *;

// menu mobile
.main-header nav {
    width: 100%;

    @media (max-width: 991px) {
        background-color: $white;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 80px;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.15s ease-in-out;
        z-index: 10;

    }

    @media (min-width: 992px) {
        display: block;
        margin-top: 0;
        width: auto;
    }
}

.show-body .main-header nav {
    @media (max-width: 991px) {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: top;
    }
}

#toggleMenu {
    display: block;
    position: relative;

    @media (min-width: 992px) {
        display: none;
    }
}

.menu-icon {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

#closeMenu {
    transform: scale(0);
    transition: all 0.15s ease-in-out;
}

#openMenu {
    transform: scale(1);
    transition: all 0.15s ease-in-out;
}

.show-body {
    #closeMenu {
        transform: scale(1);
    }

    #openMenu {
        transform: scale(0);
    }
}
