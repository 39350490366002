@use '../utils/variables' as *;

main.slug-novinky-article,
main.slug-news-article,
main.slug-nabizime-article {
    article.article {
        display: grid;
        gap: 10px 20px;
        grid-template-areas:
            "img"
            "title"
            "lead";
        grid-template-columns: 1fr;
        grid-template-rows: auto 27px auto;

        @media (min-width: 768px) {
            grid-template-areas:
                "title img"
                "lead img";
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 27px 1fr;
        }

        .article-featured-picture {
            grid-area: img;
            margin-bottom: 0;

            img {
                display: block;
                width: 100%;
            }
        }

        .lead {
            display: block;
            grid-area: lead;

            > * {
                color: $black;
                font-size: $fs-p1;
            }
        }

        .meta {
            display: flex;
            grid-area: title;
            justify-content: space-between;

            > * {
                display: none;
            }

            .date {
                display: block;
            }

            > *,
            a {
                color: $black;
                font-family: 'Poppins-Regular', sans-serif;
                font-size: $fs-p1;
                margin-bottom: space(6);
                text-decoration: none;
            }

        }
    }

    .aldryn-download,
    .article.article h2,
    .tags {
        display: none;
    }

    .next-prev-article {
        display: flex;
    }
}

/* stylelint-disable selector-max-compound-selectors */
main.slug-ke-stazeni-article,
main.slug-download-article {
    background-color: map-get($get-secondary, '100');

    > article > .container .djangocms-newsblog-article-detail > article.article {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .article-title {
            display: none;
        }

        .article-featured-picture {
            line-height: 1;
            margin-bottom: 0;
            width: 100%;

            @media (min-width: 768px) {
                width: 66.66%;
            }

            img {
                object-fit: cover;
                width: 100%;
            }

            iframe,
            video {
                background-color: $black;
                height: 418px;
                width: 100%;

                @media (min-width: 768px) {
                    height: 418px;
                }

                @media (min-width: 1900px) {
                    height: 500px;
                }
            }

            .btn {
                margin: space(3) space(3) 0 0;
            }
        }

        > .lead {
            background: $white;
            display: block;
            margin-top: space(3);
            padding: 20px;
            width: 100%;

            @media (min-width: 768px) {
                margin-left: 10px;
                margin-top: 0;
                width: calc(33.33% - 24px);
            }

            p {
                font-size: $fs-p2;
                word-break: keep-all;
            }
        }

        .content {
            margin-top: 20px;
            width: 100%;

            > p,
            > :is(h1, h2, h3, h4, h5, h6) {
                background-color: $white;
                margin: 0;
                padding: 0 20px 1rem;
                word-break: keep-all;
            }

            p:first-of-type {
                padding-top: 20px;
            }

            .btn {
                margin-bottom: space(6);
                margin-left: space(3);

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }

        .category-icon {
            display: none;
        }

        .tags {
            display: none;
        }
    }

    .aldryn-download {
        margin-top: 80px;
        padding-top: 20px;

        @media (max-width: 540px) {
            padding: 20px;
            padding-top: 10px;
        }

        article.article {
            width: 100%;
        }

        @media (min-width: 992px) {
            article.article {
                width: 33%;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .djangocms-newsblog-article-list {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px) {
            flex-direction: row;
        }
    }

    .djangocms-newsblog-article-list {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px) {
            flex-direction: row;
        }
    }

    article {
        padding-top: 1px;
    }

    > article > .container {
        padding-bottom: 0 !important;

        > .djangocms-newsblog-article-list {
            padding-bottom: 0 !important;
        }
    }
}

/* stylelint-enable selector-max-compound-selectors */

/* stylelint-disable selector-max-compound-selectors */
main.slug-akce-article,
main.slug-actions-article {
    > article > .container .djangocms-newsblog-article-detail > article.article {
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
            flex-direction: unset;
            flex-wrap: wrap;
        }

        .newsblog-serial {
            display: none;
        }

        > * {
            width: 100%;
        }

        .article-featured-picture {
            display: flex;
            justify-content: center;
            margin-top: space(8);
            order: 10;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;

                @media (min-width: 768px) {
                    max-width: 66%;
                    width: auto;
                }
            }
        }

        h2 {
            display: none;
        }

        .table-actions {
            margin-top: 0;
            max-width: 100%;
            order: 1;

            @media (min-width: 992px) {
                max-width: 600px;
            }

            @media (min-width: 1200px) {
                order: 2;
                padding-left: space(3);
            }

            caption {
                padding-top: 0;
            }
        }

        .content {
            order: 3;
            padding-top: space(5);

            h5 {
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
        }

        .lead {
            display: block;
            margin-top: space(5);
            order: 2;

            @media (min-width: 1200px) {
                margin-top: space(0);
                order: 1;
                width: calc(100% - 600px);
            }

            p {
                font-size: $fs-p1;
            }
        }

    }

    .aldryn-download {
        display: none;
    }

    article {
        padding-top: 1px;
    }
}
/* stylelint-enable selector-max-compound-selectors */

.related-article {
    list-style-type: none;
    padding: space(3) 0 0;

    @media (min-width: 992px) {
        padding-top: space(4);
    }

    > li {
        width: 100%;

        @media (min-width: 992px) {
            width: 33.33%;
        }

        > p {
            font-size: $fs-p4;
            padding: 0 space(1);
            word-wrap: break-word;

            @media (min-width: 768px) {
                padding: 0 space(3);
            }

            &:first-of-type {
                display: block;
                margin: 0;
                padding: 0;
            }
        }

        :nth-child(n+5) {
            display: none;
        }

        :nth-child(3) {
            margin: 0;
            padding: 0;
        }

        :nth-child(4) {
            @media (min-width: 768px) {
                /* stylelint-disable */
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                overflow: hidden;
                text-overflow: ellipsis;
                /* stylelint-enable */
            }
        }

    }

    h3 {
        font-size: $fs-p1;
        margin: 0;
        padding: 0 space(1);

        a {
            text-decoration: none;

            @media (min-width: 992px) {
                /* stylelint-disable */
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                min-height: 47px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* stylelint-enable */
            }
        }

        @media (min-width: 768px) {
            font-size: $fs-p2;
            padding: space(4) space(3) space(2);
        }
    }

    img {
        height: 170px;
        object-fit: cover;
        width: 100%;

        @media (min-width: 768px) {
            height: 240px;
        }

        @media (min-width: 1900px) {
            height: 336px;
        }
    }
}
