@use '../utils/variables' as *;

a p {
    margin: 0;
}

.border-top-left-0 {
    border-top-left-radius: 0 0;
}

.border-top-right-0 {
    border-top-right-radius: 0 0;
}

.border-bottom-right-0 {
    border-bottom-right-radius: 0 0;
}

.border-bottom-left-0 {
    border-bottom-left-radius: 0 0;
}

.box-shadow-success > * {
    border-bottom: 4px solid $success;
    display: inline-block;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (min-width: 992px) {
    .box-shadow-success > * {
        padding-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.position-lg-relative {
    @media (min-width: 992px) {
        position: relative;
    }
}

.container,
.container-xl {
    @media (min-width: 1900px) {
        max-width: 1836px;
    }
}

.img-mw-100 img {
    max-width: 100%;
}

.img-100 img {
    width: 100%;
}

img.captcha {
    height: 40px;
    padding-bottom: space(2);
    width: 90px;
}

.bg-full-white {
    box-shadow: 200px 0 0 0 $white, -200px 0 0 0 $white;

    @media (min-width: 992px) {
        box-shadow: 500px 0 0 0 $white, -500px 0 0 0 $white;
    }
}

.link-hover-white a:hover {
    color: $white;
}

.newsletter {
    input[type="submit"] {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        margin: 40px auto 0;
        width: 320px;

        &:hover {
            background-color: $dark;
            border-color: $dark;
            color: $white;
        }

        &:active {
            background-color: map-get($get-dark , '800');
            border-color: map-get($get-dark , '800');
            color: $white;
        }
    }
}

.messagelist,
.aldryn-form-success-message,
.aldryn-form-redirected-shortly {
    --bs-alert-bg: #cfe2ff;
    --bs-alert-border-color: #b6d4fe;
    --bs-alert-border-radius: 0.375rem;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-color: #084298;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius);
    color: var(--bs-alert-color);
    list-style: none;
    margin-bottom: var(--bs-alert-margin-bottom);
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    position: relative;

    p {
        margin: 0;
    }
}
