@use '../utils/variables' as *;

.mapycz-panorama {
    display: none;
    position: relative;

    .close {
        color: #fff;
        cursor: pointer;
        font-size: 3em;
        font-weight: bold;
        height: 32px;
        position: absolute;
        right: 8px;
        top: -7px;
        width: 32px;
    }
}

.card {
    span[class$=strong] {
        font-family: 'Poppins-Bold', sans-serif;
    }
}

.card-header {
    background: transparent;
    border-bottom: 0;
}

.card-body {
    span {
        display: block;
        padding-bottom: space(2);
    }
}

.card-footer {
    background: transparent;
    border-top: 0;

    a {
        display: block;
    }
}

.mapycz-panorama {
    .errormessages {
        list-style: none;
        padding: 0;

        .error {
            background-color: map-get($get-danger, '800');
            color: $white;
            font-family: "Poppins-Regular", sans-serif;
            font-size: 16px;
            font-weight: bold;
            padding: 16px;
        }
    }
}

mapycz-suggest-address {
    width: 100%;

    ~ .smap-suggest {
        position: absolute;
        top: 300px;

        @media (min-width: 768px) {
            top: 320px;
        }

        @media (min-width: 992px) {
            top: 470px;
        }

        @media (min-width: 1900px) {
            top: 300px;
        }

        @media (min-width: 2000px) {
            top: 370px;
        }
    }
}
