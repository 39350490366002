@use '../utils/variables' as *;

.carousel {
    margin-bottom: space(4);
    overflow: hidden;
    padding-bottom: space(3);

    @media (min-width: 1400px) {
        margin-bottom: 80px;
    }

    .sr-only {
        display: none;
    }
}

.carousel-control-prev {
    left: -6%;
}

.carousel-control-next {
    right: -6%;
}

.carousel-indicators {
    margin-bottom: 0;

    @media (min-width: 992px) {
        margin-bottom: 50px;
    }

    @media (min-width: 1400px) {
        margin-bottom: 0;
    }

    button[data-bs-target] {
        background-color: $info;
        cursor: pointer;
        height: 3px;
        margin: 0 3px;
        width: 30px;

        &.active {
            background-color: $primary;
            cursor: auto;
        }
    }
}

.carousel-caption {
    left: 2%;
    right: 2%;
}

.carousel-control-next,
.carousel-control-prev {
    opacity: 1;
}

.carousel {
    @media (min-width: 992px) {
        height: 470px;
    }

    @media (min-width: 1200px) {
        height: 550px;
    }

    @media (min-width: 1900px) {
        height: 770px;
    }

    &-inner {
        @media (min-width: 992px) {
            height: 100%;
        }
    }
}

.carousel-item {
    height: 100%;

    > img {
        height: 100%;
    }
}

.carousel-caption {
    bottom: 0;

    &.d-none.d-md-block {
        align-items: center;
        display: flex !important;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        max-width: 0;

        @media (min-width: 992px) {
            margin: auto;
        }
    }

    img {
        display: none;
        height: 100%;

        @media (min-width: 992px) {
            display: block;
            margin: 0 space(4);
            width: 50%;
        }
    }

    h2 {
        color: $white;
        margin-bottom: space(4);
    }

    h2,
    p {
        @media (min-width: 992px) {
            margin-bottom: space(5);
        }
    }
}

.carousel-images {
    .d-none.d-md-block {
        flex-direction: row;

        @media (min-width: 992px) {
            max-width: 100%;
        }
    }

    aside {
        align-items: start;
        display: flex;
        flex-direction: column;
        margin: 0 space(4);
        text-align: left;

        @media (min-width: 1900px) {
            margin: 0 space(9) 0 space(5);
        }
    }
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0);
}

.active.carousel-item-right,
.carousel-item-next {
    transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev {
    transform: translateX(-100%);
}
