@use './../utils/variables' as *;

// Logo
.logo {
    height: 48px;
    margin: 16px 0;

    @media (min-width: 992px) {
        height: 60px;
        margin: space(3) 0;
    }
}
