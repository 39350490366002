@use '../utils/variables.scss' as *;

// nav mobile all pages
@media (max-width: 991px) {
    nav {
        box-shadow: 0 2px 5px rgba(0, 56, 147, 0.1);
    }

    .page-navigation {
        li {
            display: block;
            margin: 0;
            padding: 0.475rem 0;
            text-align: right;
        }

        a {
            padding: 0.85rem 0;
            width: 100%;
        }

        .undermenu {
            box-shadow: none;
            opacity: 1;
            padding: 0;
            position: relative;
            transform: translate(0, 0);
            visibility: visible;
        }

        .open-undermenu {
            transform: rotateX(180deg);
        }
    }
}
