@use '../utils/variables' as *;

form {
    > * {
        display: block;
        width: 100%;
    }

    label {
        font-size: $fs-p3;
        margin-bottom: space(2);
    }

    abbr[title] {
        color: $danger;
        text-decoration: none;
    }

    input,
    textarea,
    select {
        border: 1px solid map-get($get-dark , '400');
        border-radius: $border-radius;
        color: $black;
        font-size: $fs-p4;
        margin-bottom: space(3);
        padding: space(3);

        &::placeholder {
            color: map-get($get-dark , '400');
        }

        &:hover,
        &:focus {
            border: 1px solid map-get($get-dark , '900');
            color: map-get($get-dark , '900');
            outline: 0;
        }

        ~ .help-text {
            margin-bottom: 20px;
        }
    }

    .text-danger,
    .errormessages {
        color: $danger;
        font-size: $fs-p4;
        list-style-type: none;
        padding: space(1) 0 0 0;
    }

    .help-text {
        font-size: $fs-p4;
        margin: 0;
        padding-top: space(1);
    }

    button[type="submit"] {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        margin: 40px auto 0;
        width: 320px;

        &:hover {
            background-color: $dark;
            border-color: $dark;
            color: $white;
        }

        &:active {
            background-color: map-get($get-dark , '800');
            border-color: map-get($get-dark , '800');
            color: $white;
        }
    }

    ul[id*='id_radioselect'] {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0 0 space(3);
        padding: 0;

        @media (min-width: 992px) {
            flex-direction: row;
        }

        li {
            @media (min-width: 992px) {
                padding: 0 space(4);
            }
        }

        input {
            width: auto;
        }
    }

    .checkbox {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0 0 space(3);
        padding: 0;

        @media (min-width: 992px) {
            flex-direction: row;
        }

        > p {
            margin-bottom: space(2);
            width: 100%;
        }

        label {
            @media (min-width: 992px) {
                padding: 0 space(4);
            }
        }

        input {
            width: auto;
        }
    }
}

.base-form {
    input,
    textarea,
    select {
        margin-bottom: 0;
    }

    button[type="submit"] {
        margin: 0 auto;
    }
}

input[type=checkbox] {
    width: space(3);
}

.input-checkbox {
    align-items: center;
    display: flex;
    margin-bottom: space(3);

    > * {
        margin: 0;
        padding: 0;
    }

    label {
        order: 2;
    }

    input {
        margin-right: space(3);
        order: 1;
    }
}

.container-radioselect {
    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0 0 space(3);
        padding: 0;

        @media (min-width: 992px) {
            flex-direction: row;
        }

        li {
            @media (min-width: 992px) {
                padding: 0 space(4);
            }

            label {
                display: flex;
            }
        }

        input {
            margin: 0 space(2) 0 0;
            width: auto;
        }
    }
}
