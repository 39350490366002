@use '../utils/variables.scss' as *;

.results-list {
    list-style-type: none;
    margin: 0 0 space(7) 0;
    padding: 0;

    li {
        border-bottom: 1px solid map-get($get-primary , '400');
        padding-top: space(3);

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 3fr 10fr;
        }

        h3 {
            @media (min-width: 768px) {
                grid-column: 1 / 2;
            }
        }

        p {
            @media (min-width: 768px) {
                grid-column: 2 / 2;
            }
        }
    }
}
