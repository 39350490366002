@use '../utils/variables' as *;

.pagenav ul,
.pagination:not(.next-prev-article) {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    min-height: 50px;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-top: 22px;

    li {
        list-style-type: none;
        margin: 0 10px 10px;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    a,
    li > span {
        border: 1px solid $primary;
        border-radius: $border-radius;
        display: inline-block;
        font-size: $fs-p3;
        padding: space(2) space(3);
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: $primary;
            color: $white;
        }
    }

    li > span {
        background-color: $primary;
        color: $white;
    }

    .active {
        a {

            background-color: $primary;
            color: $white;
        }
    }

    .badge {
        display: none;
    }
}

.next-prev-article {
    align-items: center;
    display: none;
    flex-direction: column;
    margin: 0;
    padding: space(4) 0 space(3);

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
    }

    li {
        list-style-type: none;
        margin: 0;
        padding: space(2);

        @media (min-width: 768px) {
            padding: 0 space(5);
        }
    }
}
