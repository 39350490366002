@use '../utils/variables' as *;

.btn,
button[type="submit"] {
    border-radius: 7px;
    font-family: 'Poppins-Bold', sans-serif;
    font-size: $fs-p2;
    padding: 19px 56px;
}

.btn-lg {
    padding-bottom: 25px;
    padding-top: 25px;
}

.btn-sm {
    padding-bottom: 13px;
    padding-top: 13px;
}

.btn-primary {
    --bs-btn-active-bg: rgba(51, 51, 51, 0.8);
    --bs-btn-active-border-color: rgba(51, 51, 51, 0.8);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-bg: #003893;
    --bs-btn-border-color: #003893;
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-bg: #333;
    --bs-btn-hover-bg: #333;
    --bs-btn-hover-border-color: #333;
    --bs-btn-hover-color: var(--bs-white);
}
