@use "sass:math";

@mixin btn-color($bg, $bc, $c, $h-bg, $h-bc, $h-c) {
    background-color: $bg;
    border-color: $bc;
    color: $c;

    &:hover,
    &:focus,
    &:active {
        background-color: $h-bg;
        border-color: $h-bc;
        color: $h-c;
    }
}

/*
font-size: calc([minimum size] + ([maximum size] - [minimum size])
* ((100vw - [minimum viewport width])
/ ([maximum viewport width] - [minimum viewport width])));
*/
@function stripunit($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size ) {
    $min: unit($min-vw);
    $max: unit($max-vw);
    $minf: unit($min-font-size);
    $maxf: unit($max-font-size);

    @if $min ==$max and $min ==$minf and $min ==$maxf {
        body & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{stripunit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{stripunit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}
