@use '../utils/variables' as *;
@use './buttons';

/* bootstap settings start*/
:root {
    --bs-danger: $danger;
    --bs-dark: $dark;
    --bs-info: $info;
    --bs-primary: $primary;
    --bs-secondary: $secondary;
    --bs-success: $success;
    --bs-warning: $warning;
    --bs-danger-rgb: 206, 17, 38;
    --bs-dark-rgb: 51, 51, 51;
    --bs-info-rgb: 214, 214, 214;
    --bs-primary-rgb: 0, 56, 147;
    --bs-secondary-rgb: 109, 9, 171;
    --bs-success-rgb: 87, 163, 0;
    --bs-warning-rgb: 245, 128, 37;

    --bs-font-sans-serif: 'Poppins-Regular', sans-serif;
    --bs-body-font-size: 1em;
    --bs-body-line-height: 1.5;

    --bs-link-color: #003893;
    --bs-link-hover-color: #003893;
    --bs-body-color: #333;
}

/* bootstap settings end*/

/* custom colors start*/
$user-colors: (
    "danger": --bs-danger-rgb,
    "dark": --bs-dark-rgb,
    "info": --bs-info-rgb,
    "primary": --bs-primary-rgb,
    "secondary": --bs-secondary-rgb,
    "success": --bs-success-rgb,
    "warning": --bs-warning-rgb
);

/* Create classes for all variable colors*/
// .text-color-(800, 600, 400, 200, 100)
// .bg-color-(800, 600, 400, 200, 100)
@mixin create-colors($name, $color) {
    @for $num from 1 through 9 {
        @if $num % 2==0 or $num==1 {
            .text-#{$name}-#{$num}00 {
                color: rgba(var($color), calc($num/ 10));
            }

            .bg-#{$name}-#{$num}00 {
                background-color: rgba(var($color), calc($num/ 10));
            }
        }
    }
}

@each $name, $color in $user-colors {
    @include create-colors($name, $color);
}

/* custom colors end */
