@use '../utils/mixin';
@use '../utils/variables' as *;

// Title h 1-6 and display sizefs-
$min-width: 320px;
$max-width: 768px;

body * {
    line-height: 1.5;
}

h1,
.h1 * {
    @include mixin.fluid-type($min-width, $max-width, 28px, $fs-h1);

    font-family: 'Poppins-Bold', sans-serif;
    margin-bottom: 0;
}

h2,
.h2 * {
    @include mixin.fluid-type($min-width, $max-width, 24px, $fs-h2);

    font-family: 'Poppins-Bold', sans-serif;
    margin-bottom: 0;
}

h3,
.h3 * {
    @include mixin.fluid-type($min-width, $max-width, 20px, $fs-h3);

    font-family: 'Poppins-Bold', sans-serif;
    margin-bottom: 0;
}

h4,
.h4 * {
    @include mixin.fluid-type($min-width, $max-width, 18px, $fs-h4);

    font-family: 'Poppins-Bold', sans-serif;
    margin-bottom: 0;
}

h5,
.h5 * {
    @include mixin.fluid-type($min-width, $max-width, 24px, $fs-h3);

    border-bottom: 4px solid $success;
    display: inline-block;
    font-family: 'Poppins-Bold', sans-serif;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (min-width: 992px) {
        padding-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

p {
    @include mixin.fluid-type($min-width, $max-width, 16px, $fs-p2);
}

.display-1,
.display-1 * {
    @include mixin.fluid-type($min-width, $max-width, 16px, $fs-p1);
}

.display-2,
.display-2 * {
    @include mixin.fluid-type($min-width, $max-width, 15px, $fs-p2);
}

.display-3,
.display-3 * {
    @include mixin.fluid-type($min-width, $max-width, 14px, $fs-p3);
}

.display-4,
.display-4 * {
    @include mixin.fluid-type($min-width, $max-width, 12px, $fs-p4);
}

.display-5,
.display-5 * {
    @include mixin.fluid-type($min-width, $max-width, 10px, $fs-p5);
}

strong {
    display: inline-block;
    font-family: 'Poppins-Bold', sans-serif;
}

.text-xl {
    @media (min-width: 1900px) {
        h4,
        .h4 * {
            font-size: $fs-h2;
        }

        .display-4,
        .display-4 * {
            font-size: $fs-p1;
        }
    }
}

@for $index from 1 through 6 {
    h#{$index} {
        color: $primary;
        margin-bottom: 1rem;
    }
}

.text-white * {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}
