@use './../utils/variables' as *;

.breadcrumb {
    background-color: $white;
    color: map-get($get-dark, '400');
    font-size: $fs-p4;
    margin: 1px auto;

    .container {
        margin: 20px auto 10px;

        @media (min-width: 992px) {
            margin: 40px auto 20px;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;

        &:first-of-type {
            &::before {
                content: "";
            }

            a::after {
                content: url('../img/home.svg');
                padding-left: calc(var(--bs-gutter-x) * 0.5);

            }

            a {
                border: 1px solid;
                color: transparent;
                display: inline-block;
                font-size: 2px;
                width: 25px;

                &:hover::after {
                    content: url('../img/home-hover.svg');
                }
            }
        }

        &::before {
            content: "/";

        }
    }

    a {
        color: map-get($get-dark, '400');
        text-decoration: none;

        &:hover {
            color: map-get($get-primary, '600');
        }
    }

    .active {
        color: $dark;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .separator {
        display: none;
    }
}
