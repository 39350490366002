@use '../utils/variables' as *;

// Styles for adding elements over administration toolbar and cms plugins
.jumbotron {
    background-color: $info;
    border-radius: 0.3rem;
    margin-bottom: 2rem;
    padding: 2rem 1rem;

    @media (min-width: 576px) {
        padding: 4rem 2rem;
    }
}

.blockquote-footer {
    margin-top: 0;
}

.alert > * {
    margin: 0;
}

.marker {
    background-color: $info;
}

table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color);
    margin-bottom: 1rem;
    vertical-align: top;
    width: 100%;
}

table > :not(caption) > * > * {
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    padding: 0.5rem;
}

table > tbody {
    vertical-align: inherit;
}

table > thead {
    vertical-align: bottom;
}

thead {
    color: $primary;
}

table[border="0"] {
    th,
    td {
        border: 0;
    }
}

a {
    color: $primary;

    &:hover {
        color: $primary-hv;
    }
}
