@use '../utils/variables.scss' as *;

.newsblog-serial {
    margin-top: space(4);

    article.article {
        background-color: $white;
        margin-top: space(3);

        .lead {
            display: none;
        }

        .article-title {
            padding-top: 0;
        }
    }

    .nav-tabs .nav-link {
        background-color: transparent;
        border-radius: 0;
        color: #003994;
        margin: 12px space(3) 4px;
        min-width: auto;
        padding: space(2) 10px;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.active {
            background-color: transparent;
            border-bottom: 4px solid $secondary;
            color: $primary;

            &::after,
            &::before {
                content: none;
            }
        }
    }
}

.owl-carousel {
    position: relative;

    .owl-nav.disabled {
        display: flex;
        height: 0;
        justify-content: space-between;
        position: absolute;
        top: calc(50% - 65px);
        width: 100%;
    }

    span[aria-label="Previous"],
    span[aria-label="Next"] {
        color: $white;
        font-family: cursive;
        font-size: 100px;
        line-height: 1;
    }

    .owl-stage-outer {
        transform: translate3d(12px, 0, 0);
    }
}

//row
.owl-stage {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-top: calc(-1 * var(--bs-gutter-y));

    > * {
        flex-shrink: 0;
        margin-top: var(--bs-gutter-y);
        max-width: 100%;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        width: 100%;
    }
}

.tab-content > .owl-carousel.owl-loaded {
    display: none;
}

.tab-content > .owl-carousel.owl-loaded.active {
    display: block;
}

.owl-item {
    @media (min-width: 580px) {
        max-width: 258px;
    }

    @media (min-width: 768px) {
        max-width: 348px;
    }

    @media (min-width: 992px) {
        max-width: 468px;
    }

    @media (min-width: 1200px) {
        max-width: 372px;
    }

    @media (min-width: 1400px) {
        max-width: 324px;
    }

    @media (min-width: 1900px) {
        max-width: 453px;
    }
}
