/* font start */
@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.ttf');
}

/* font end */

/* Base colors start */
$danger: #ce1126; // 206, 17, 38
$dark: #333; // 51, 51, 51
$info: #d6d6d6; // 214, 214, 214
$primary: #003994; // 0, 56, 147
$primary-hv: #0d6efd; // 13, 110, 253
$secondary: #6d09ab; // 109, 9, 171
$success: #57a300; // 87, 163, 0
$warning: #f58025; // 245, 128, 37
$white: #fff;
$black: #000;
$lightpurple: #f0e6f7;
/* Base colors end */

/* Create map colors, for use in code */
// map-get($get-primary , '800')
$get-danger: (
    '800': rgba($danger, 0.8),
    '600': rgba($danger, 0.6),
    '400': rgba($danger, 0.4),
    '200': rgba($danger, 0.2),
    '100': rgba($danger, 0.1),
);

$get-dark: (
    '800': rgba($dark, 0.8),
    '600': rgba($dark, 0.6),
    '400': rgba($dark, 0.4),
    '200': rgba($dark, 0.2),
    '100': rgba($dark, 0.1),
);

$get-info: (
    '800': rgba($info, 0.8),
    '600': rgba($info, 0.6),
    '400': rgba($info, 0.4),
    '200': rgba($info, 0.2),
    '100': $white,
);

$get-primary: (
    '800': rgba($primary, 0.8),
    '600': rgba($primary, 0.6),
    '400': rgba($primary, 0.4),
    '200': rgba($primary, 0.2),
    '100': rgba($primary, 0.1),
);

$get-secondary: (
    '800': rgba($secondary, 0.8),
    '600': rgba($secondary, 0.6),
    '400': rgba($secondary, 0.4),
    '200': rgba($secondary, 0.2),
    '100': rgba($secondary, 0.1),
);

$get-success: (
    '800': rgba($success, 0.8),
    '600': rgba($success, 0.6),
    '400': rgba($success, 0.4),
    '200': rgba($success, 0.2),
    '100': rgba($success, 0.1),
);

$get-warning: (
    '800': rgba($warning, 0.8),
    '600': rgba($warning, 0.6),
    '400': rgba($warning, 0.4),
    '200': rgba($warning, 0.2),
    '100': rgba($warning, 0.1),
);

$fs-h1: 32px;
$fs-h2: 28px;
$fs-h3: 24px;
$fs-h4: 20px;

$fs-p1: 18px;
$fs-p2: 16px;
$fs-p3: 14px;
$fs-p4: 12px;
$fs-p5: 10px;

// Spacing
// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
    0: 0,
    // 4
    1: $spacer * 0.25,
    // 8
    2 :$spacer * 0.5,
    // 18
    3: $spacer * 1.125,
    // 24
    4: $spacer * 1.5,
    // 32
    5: $spacer * 2,
    // 40
    6: $spacer * 2.5,
    // 48
    7: $spacer * 3,
    // 56
    8: $spacer *3.5,
    // 64
    9: $spacer * 4,
    // 144
);

@function space($space) {
    @return map-get($spacers, $space);
}

$border-radius: 7px;

@function bg-column-aldryns($bg) {
    @return linear-gradient(to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(var(--bs-gutter-x) * 0.5),
    $bg calc(var(--bs-gutter-x) * 0.5),
    $bg calc(100% - var(--bs-gutter-x) * 0.5),
    rgba(0, 0, 0, 0) calc(100% - var(--bs-gutter-x) * 0.5),
);}
