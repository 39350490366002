@use '../utils/variables' as *;

.aldryn-category {
    ul {
        align-items: start;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        min-height: 50px;
        padding: 0;

        li {
            list-style-type: none;
            margin: 0 10px 10px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        a {
            border: 1px solid $primary;
            border-radius: $border-radius;
            display: inline-block;
            font-size: $fs-p3;
            padding: space(2) space(3);
            text-decoration: none;

            &:hover,
            &:focus {
                background-color: $primary;
                color: $white;
            }
        }

        span {
            display: none;
        }

        .active {
            a {

                background-color: $primary;
                color: $white;
            }
        }

        .badge {
            display: none;
        }
    }
}
