@use '../utils/variables.scss' as *;

main.slug-nabizime,
main.slug-offer {
    > article > section,
    .article-title {
        display: none;
    }

    .djangocms-newsblog-article-list,
    .aldryn-newsblog-list {
        article {
            margin: space(6) 0 0;

            &:first-of-type {
                margin-top: 9px;

                @media (min-width: 992px) {
                    margin-top: 29px;
                }
            }

            @media (min-width: 992px) {
                display: flex;
            }

            .visual {
                margin-bottom: 0;
                width: 100%;

                @media (min-width: 992px) {
                    flex-shrink: 0;
                    height: 100%;
                    object-fit: cover;
                    width: 416px;
                }

                img {
                    height: 100%;
                    min-height: 219px;
                }
            }

            .lead {
                background-color: map-get($get-secondary, '100');
                display: block;
                padding: space(3);

                @media (min-width: 992px) {
                    box-shadow: 500px 0 0 0 map-get($get-secondary, '100');
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin-left: space(4);
                }

                p {
                    font-size: $fs-p2;
                }
            }

            :is(h1, h2, h3, h4, h5, h6) {
                font-size: $fs-p1;
                margin-bottom: space(3);

                a {
                    text-decoration: underline;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        article:nth-child(even) {
            @media (min-width: 992px) {
                flex-direction: row-reverse;
            }

            .lead {
                @media (min-width: 992px) {
                    box-shadow: -500px 0 0 0 map-get($get-secondary, '100');
                    margin-left: 0;
                    margin-right: space(4);
                }
            }
        }
    }
}
