@use '../utils/variables.scss' as *;

// nav desktop all pages
.page-navigation {
    margin: 0 auto;
    padding: 0 1.7rem;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        padding-right: 0;
    }

    @media (min-width: 1200px) {
        max-width: 100%;
    }

    li {
        display: inline-block;
        margin: 0 1rem;
        padding: 0.7rem 0;
    }

    a {
        cursor: pointer;
        display: inline-block;
        font-size: $fs-p2;
        padding: 2px space(2);
        position: relative;
        text-decoration: none;
        transition: all 0.15s ease-in-out;
    }

    li:last-of-type {
        margin-right: 0;

        a {
            padding-right: 0;
        }
    }

    .undermenu {
        background-color: $white;
        box-shadow: 0 2px 5px rgba(0, 56, 147, 0.1);
        opacity: 0;
        padding: 15px 0;
        position: absolute;
        transform: translate(-9px, 11px);
        transition: 0.15s ease-in-out;
        visibility: hidden;
        z-index: 9;

        li {
            display: block;
            margin: 0;
            padding: 0;

            a {
                display: block;
                line-height: 1;
                margin: 0;
                padding: 13px 40px;
                transition: all 0.15s ease-in-out;
                width: 100%;

                &.active_link {
                    background: map-get($get-primary, '100');

                    &:hover {
                        cursor: default;
                    }
                }
            }
        }
    }

    .open-undermenu {
        padding-left: 10px;
        transition: all 0.15s ease-in-out;
    }

    a:not([href]):not([class]) {
        color: $primary;
    }

    li:hover .undermenu,
    li:focus .undermenu {
        opacity: 1;
        visibility: visible;
    }

    li:hover .open-undermenu,
    li:focus .open-undermenu {
        transform: rotateX(180deg);
    }
}
