@use '../utils/variables.scss' as *;

.safer-day {
    .header {
        img {
            max-height: 480px;
            object-fit: cover;
        }

    }

    .side-nav {
        .btn {
            font-size: $fs-p2;
            padding: 5px space(6);
        }
    }

    .content {
        .btn {
            display: block;
            margin: space(4) auto;
            width: 260px;

            @media (min-width: 992px) {
                width: 320px;
            }
        }
    }

    #download {
        a {
            display: inline-block;
            font-family: 'Poppins-Bold', sans-serif;
            font-size: $fs-p1;
            margin: space(3) 0;
            padding: space(3);
            text-decoration: none;

            @media (min-width: 992px) {
                width: 25%;
            }
        }
    }

    #program {
        margin: space(6) auto;
    }

    #map {
        margin: space(9) auto;
    }

    p {
        padding-left: space(3);
        padding-right: space(3);
    }
}
