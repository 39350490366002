@use '../utils/variables' as *;

.visual {
    img {
        height: 98px;
        object-fit: cover;
        width: 100%;

        @media (min-width: 768px) {
            height: 219px;
        }

        @media (min-width: 1900px) {
            height: 311px;
        }
    }

    a {
        text-decoration: none;
    }
}

.aldryn-newsblog-meta {
    display: none;
}

// col
.article.article {
    .visual {
        margin-bottom: space(2);
    }

    .category {
        display: none;
    }

    h2 {
        font-size: $fs-p3;
        padding: 0 space(1);

        a {
            text-decoration: none;

            @media (min-width: 768px) {
                /* stylelint-disable */
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                min-height: 47px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* stylelint-enable */
            }
        }

        @media (min-width: 768px) {
            font-size: $fs-p2;
            padding: space(5) space(3) space(2);
        }
    }

    .lead {
        display: none;
        padding: 0 space(1);
        word-break: keep-all;

        @media (min-width: 768px) {
            display: block;
            padding: 0 space(3);

            p {
                font-size: $fs-p4;
            }
        }

        * {
            padding: 0;
        }
    }

    a {
        text-decoration: none;
    }

    .meta {
        color: $primary;
        display: none;
        font-family: 'Poppins-Bold', sans-serif;
        padding: 0 space(1);

        * {
            font-size: $fs-p3;
        }

        @media (min-width: 768px) {
            padding: space(1) space(3) 0;

            * {
                font-size: $fs-p2;
            }
        }
    }

    @for $index from 1 through 6 {
        h#{$index} {
            margin-bottom: 0;
        }
    }
}

.is-homepage {

    // row
    .aldryn-newsblog-latest-articles {
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-top: calc(-1 * var(--bs-gutter-y));
    }

    .aldryn-newsblog-latest-articles > * {
        flex-shrink: 0;
        margin-top: var(--bs-gutter-y);
        max-width: 100%;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        width: 100%;
    }

    // col
    .article.article {
        background: bg-column-aldryns($white);
        margin: space(2) 0;
        width: 50%;

        @media (min-width: 992px) {
            flex: 0 0 auto;
            width: 25%;
        }
    }
}

.aldryn-download {
    .article.article {
        background: bg-column-aldryns($white);
        margin: space(2) 0;
        width: 100%;

        @media (min-width: 992px) {
            flex: 0 0 auto;
            margin: space(3) 0;
            width: 33.333%;
        }

        h2 {
            @media (min-width: 768px) {
                font-size: $fs-p1;
            }

            a {
                @media (min-width: 768px) {
                    min-height: 54px;
                }
            }
        }

        .lead {
            display: block;
        }
    }
}

.aldryn-download,
.slug-ke-stazeni,
.slug-download {
    .article.article {
        img {
            height: 170px;
            object-fit: cover;
            width: 100%;

            @media (min-width: 768px) {
                height: 240px;
            }

            @media (min-width: 1900px) {
                height: 336px;
            }
        }
    }
}

main[class^=slug-] {
    .aldryn-more-section {
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        > .aldryn-newsblog-latest-articles {
            flex-shrink: 0;
            margin-left: 0;
            margin-right: 0;
            width: 100%;

            @media (min-width: 992px) {
                width: 50%;
            }
        }

        .article.article {
            width: 50%;
        }
    }
}
