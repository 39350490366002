@use '../utils/variables.scss' as *;

div[id^=trigger-] {
    cursor: pointer;
    position: relative;

    > * {
        margin: 0;
        padding: space(3);
    }

    &::after {
        content: url('../img/arrow.svg');
        position: absolute;
        right: 2%;
        top: 34%;
        transform: rotate(0deg);
        transition: all 0.2s;
    }
}

// trigger open
div[aria-expanded=true] {
    &::after {
        transform: rotate(180deg);
    }
}

// trigger close
div[aria-expanded=false] {
    border-bottom: 1px solid map-get($get-primary, '600');
}

// content open
div[id^=container-].show {
    border-bottom: 1px solid map-get($get-primary, '600');

}

div[id^=container-] {
    > * {
        display: inline-block;
        padding-left: space(3);
        padding-right: space(3);
    }
}
