@use '../utils/variables.scss' as *;

.language-chooser {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
        display: inline-block;
        margin: 0 0 0 1rem;
        padding: 0.7rem 0;

    }

    .active {
        display: none;

        @media (min-width: 992px) {
            display: flex;
        }

        &::after {
            content: url('../img/arrow.svg');
            cursor: pointer;
            display: inline-block;
            transition: all 0.15s ease-in-out;
        }

        &:hover::after,
        &:focus::after,
        &.lang-hover::after {
            transform: rotateX(180deg);
        }
    }

    .lang:not(.active) {
        @media (min-width: 992px) {
            background-color: $white;
            box-shadow: 0 2px 5px rgb(0 56 147 / 10%);
            left: 0;
            opacity: 0;
            padding: 0;
            position: absolute;
            top: 50px;
            transition: 0.15s ease-in-out;
            visibility: hidden;
            z-index: 9;

            a {
                padding: 28px 40px;
            }
        }
    }

    a {
        cursor: pointer;
        display: inline-block;
        padding: 2px 0.5rem;
        text-decoration: none;
        transition: all 0.15s ease-in-out;
    }

    li.active:hover + li,
    .lang:not(.active):hover {
        opacity: 1;
        visibility: visible;
    }
}
