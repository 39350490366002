@use './../utils/variables' as *;

// Header-container
.header-container {
    max-height: 80px;

    @media (min-width: 768px) {
        max-height: 100%;
    }

    > .row {
        border-bottom: 1px solid map-get($get-primary, '100');
        margin: 0;
        padding: 0;
    }

    .col {
        padding: 0;
    }

    .header-mobile {
        width: 100%;
    }

    #toggleMenu {
        cursor: pointer;
        height: 36px;
        width: 36px;
    }

    @media (min-width: 992px) {
        .header-mobile {
            margin-left: 0;
            max-width: 160px;
        }
    }
}

.is-homepage .header-container > .row {
    border-bottom: 0;
}
