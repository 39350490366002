@use '../utils/variables.scss' as *;

@mixin table-actions {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 600px;

    td {
        padding-right: 0;
        text-align: right;
    }

    th {
        color: $primary;
        font-weight: 400;
        padding-left: 0;
    }

    tr {
        border-bottom: 1px solid $primary;
        display: flex;
        justify-content: space-between;
    }

    * {
        font-size: $fs-p1;
    }

    caption {
        color: $dark;
        font-family: 'Poppins-Bold', sans-serif;
        font-size: $fs-h4;
        padding-bottom: space(4);
        padding-top: space(3);
    }
}

main.slug-akce-article,
main.slug-actions-article {
    .content {
        > table {
            @include table-actions();
        }
    }
}

.table-actions {
    @include table-actions();
}
